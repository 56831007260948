<template>
    <div>
        <h1>Burning Shores Character Formatting Tool</h1>

        <h2>Character List</h2>
        <div class="create-new" v-on:click="createNewCharacter()">+ Create new character</div>

        <p v-show="characters.length > 0"><strong>Warning</strong> clicking delete is immediate, there is no recovery or confirmation at this time.</p>

        <div v-for="(character,i) in characters" v-bind:key="i" class="character-row">
            <span class="number">{{ i + 1 }}.</span>
            <div class="name" v-if="character" v-on:click="openCharacter(i + 1)">  {{  character.details && character.details.name ? character.details.name : '[ UNNAMED ]' }}</div>
            <span class="close" v-on:click="deleteCharacter(i + 1)"><i class="fa fa-trash"></i></span>
        </div>

        <hr />

        <h2>Information</h2>

        <div class="home-info">
            <h3>What is this for?</h3>

            <p>A Discord based RPG game for Vampire: The Masquerade 20th Edition called Burning Shores.</p>
            <ul>
                <li><a href="https://discord.gg/GBQJKmW" target="_blank">Discord</a></li>
                <li><a href="https://burningshores.boards.net" target="_blank">Forums</a></li>
            </ul>

            <h3>What does it do?</h3>

            <p>It allows you to play around with character ideas and get a freebie calculated version in a local format.
                All characters are stored in browser for future reference if you wish. It also allows you to
                export the character sheet to a format suitable for posting on the Burning Shores forum.</p>

            <h3>What does it NOT do?</h3>

            <p>It doesn't give you things like shareable sheets, printed output, etc. You can't send people a link to your
                sheet.</p>

            <h3>What is this doing to my browser?</h3>

            <p>Nothing. Sheets are saved to local storage, which is basically in your browser. It isn't transmitting
                any data to a server. There are no trackers or personal information scrapers. There are no cookies or
                anything of the like linked to any other service. This does mean that if you clear your history and
                cache for this site, you will lose your characters unless you have them backed up.</p>
            <p><strong>Please note: </strong> Sheets are specific to your machine and browser. If you make a sheet on
                Safari using your iPhone you will not see it on your PC using Firefox. Similarly if you make a sheet in
                a private/incognito window it will be destroyed when you close that window. This is a tool for
                formatting and experimentation, not storage.</p>

            <h3>Why is my sheet broken?</h3>

            <p>This is very much in active development, as such the underlying data may change and render old data
                (i.e. older sheets) incompatible. Apologies if this happens but you will need to manually import
                (if available) from a text file if this occurs.</p>

            <h3>What will it do in the near future?</h3>

            <p>I am investigating importing sheets in such a format as well, but that is a more
                difficult task. The third thing I want is backups as a data file for people to store wherever as a
                secondary export/import feature.</p>

            <h3>What might it eventually do?</h3>

            <p>It might allow you to build with experience spent as well, but that is massively more complex. Everything
                else is high speculation at best. If demand is high enough, there may be more advanced user development
                but I promise less than nothing.</p>

            <h3>Where do I direct any feedback or concerns?</h3>

            <p>Provide feedback to me, <strong>devolutionary</strong>, on the Discord server. Don't ask other staff,
                they don't know.</p>
        </div>


    </div>
</template>

<script>

    export default {
        name: 'SheetView',
        data() {
            return {
                characters: []
            }
        },
        methods: {
            getCharactersFromStorage: function() {
                if (!localStorage.characters) {
                    localStorage.setItem('characters', '[]');
                }
                let chars = localStorage.characters;
                this.characters = JSON.parse(chars).filter(function(v) { return !!v; });
            },
            saveCharactersToStorage: function() {
                localStorage.setItem('characters', JSON.stringify(this.characters));
            },
            openCharacter: function(id) {
                this.$router.push('/sheet/' + id);
            },
            deleteCharacter: function(id) {
                this.characters = this.characters.filter(function(v,k) {
                    return (k+1) !== id;
                });
                this.saveCharactersToStorage();
            },
            createNewCharacter: function() {
                this.characters.push({
                    details: {
                        chartype: '',
                        sect: '',
                        freebies: '30',
                        experience: '0',
                        name: '',
                        clan: '',
                        nature: '',
                        demeanor: '',
                        sire: '',
                        concept: '',
                        weakness: ''
                    },
                    attributes: {
                        Physical: {
                            Strength: [1, ''],
                            Dexterity: [1, ''],
                            Stamina: [1, '']
                        },
                        Social: {
                            Charisma: [1, ''],
                            Manipulation: [1, ''],
                            Appearance: [1, '']
                        },
                        Mental: {
                            Perception: [1, ''],
                            Intelligence: [1, ''],
                            Wits: [1, '']
                        }
                    },
                    abilities: {
                        Talents: {
                            Alertness: [0, ''],
                            Athletics: [0, ''],
                            Awareness: [0, ''],
                            Brawl: [0, ''],
                            Empathy: [0, ''],
                            Expression: [0, ''],
                            Intimidation: [0, ''],
                            Leadership: [0, ''],
                            Streetwise: [0, ''],
                            Subterfuge: [0, '']
                        },
                        Skills: {
                            'Animal Ken': [0, ''],
                            Crafts: [0, ''],
                            Drive: [0, ''],
                            Etiquette: [0, ''],
                            Firearms: [0, ''],
                            Larceny: [0, ''],
                            Melee: [0, ''],
                            Performance: [0, ''],
                            Stealth: [0, ''],
                            Survival: [0, '']
                        },
                        Knowledges: {
                            Academics: [0, ''],
                            Computer: [0, ''],
                            Finance: [0, ''],
                            Investigation: [0, ''],
                            Law: [0, ''],
                            Medicine: [0, ''],
                            Occult: [0, ''],
                            Politics: [0, ''],
                            Science: [0, ''],
                            Technology: [0, '']
                        }
                    },
                    additionalAbilities: {
                        Talents: [],
                        Skills: [],
                        Knowledges: []
                    },
                    backgrounds: [],
                    disciplines: [],
                    virtues: [
                        { name: 'Conscience', level: 1 },
                        { name: 'Self-Control', level: 1},
                        { name: 'Courage', level: 1}
                    ],
                    path: '',
                    pathLevel: 1,
                    willpower: 1,
                    rituals: [],
                    merits: [],
                    flaws: [],
                    equipment: [],
                    freebies: {
                        attributes: [],
                        abilities: [],
                        backgrounds: 0,
                        disciplines: 0,
                        virtues: 0,
                        path: 0,
                        willpower: 0,
                        merits: 6,
                        flaws: 12
                    }
                });

                this.saveCharactersToStorage();
                this.$router.push('/sheet/' + (this.characters.length - 1));
            }
        },
        mounted() {
            this.getCharactersFromStorage();
        }
    }
</script>

<style lang="scss" scoped>
    hr {
        margin: 48px 0;
    }

    .home-info {
        margin: 24px 0;
        h3 {
            margin: 0 12px 18px;
        }
        p {
            margin-bottom: 36px;
        }
        ul {
            list-style: none;
            margin-top: -18px;
            margin-bottom: 36px;
            a {
                text-decoration: none;
                font-weight: bold;
                color: red;
            }
        }
    }

    .character-row {
        background: #ddd;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px;
        span.number {
            font-weight: bold;
            font-size: 1.2em;
            display: inline-block;
            width: 60px;
            height: 60px;
            background: black;
            color: white;
            line-height: 60px;
            text-align: center;
        }
        div.name {
            line-height: 60px;
            flex: 1 1 auto;
            font-size: 1.4em;
            color: red;
            text-decoration: underline;
            cursor: pointer;
            padding: 0 16px;
        }

        span.close {
            display: inline-block;
            margin: 0 8px;
            color: #aaa;
            cursor: pointer;
            width: 40px;
            &:hover {
                color: black;
            }
            i {
                font-size: 1.4em;
                line-height: 60px;
            }
        }
    }

    .create-new {
        display: inline-block;
        padding: 0 0 16px;
        color: red;
        text-decoration: underline;
        cursor: pointer;
    }
</style>